body {
  padding: 0;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  color: #333;
  overflow-y: auto;
  overflow-x: hidden;
}

div#remote video {
  width: 190px;
  height: 150px;
}

.slick-slide img {
  margin: auto;
  width: 100vw;
  height: 100vh;
}

.simplescreenfooterRoot {
  width: 100%;
  position: absolute;
  bottom: 1% !important;
  display: grid;
  place-items: center;
  grid-template-columns: 17% 40% 25% 5%;
}

.footerRoot {
  width: 100%;
  position: absolute;
  bottom: 1% !important;
  display: grid;
  place-items: center;
  grid-template-columns: 0.3fr auto 0.3fr;
}
